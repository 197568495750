import MessageViewer from '../components/appComponents/messageViewer/MessageViewer';
import {Container} from "react-bootstrap"
import { useContext } from 'react';
import { AttributeContext } from '../components/universal/Attributes';
import UserDashboard from '../components/appComponents/userDashboard/UserDashboard';

function MessageViewerPage() {
  const Attributes = useContext(AttributeContext);
  const { mfa } = Attributes;

  return (
    <>
    {mfa === "SOFTWARE_TOKEN_MFA" ? (
    <Container fluid>
      <MessageViewer />
    </Container>
    ) : (
      <UserDashboard />
    )}
  </>
  );
}

export default MessageViewerPage;
