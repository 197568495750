import { Card } from "react-bootstrap";

const Privacy = () => {
  return (
    <Card className="centeredCard mb-1">
      <Card.Body>
      <Card.Title as="h1">Privacy</Card.Title>
      <br />
      This site is governed by the same Privacy Policy as the fema.gov website. For more details, read the fema.gov <a href="https://www.fema.gov/about/website-information/privacy-policy">Privacy Policy</a>.
      <br />
      </Card.Body>
    </Card>
  );
};

export default Privacy;