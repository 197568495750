import React, { createContext, useState, useMemo } from "react";

// create a attribute context
const AttributeContext = createContext();

// assign values for cogid, stagingFlag, email, and mfa to the attribute context
const Attribute = (props) => {
  const [cogid, setCogid] = useState('');
  const [stagingFlag, setStagingFlag] = useState('');
  const [email, setEmail] = useState('');
  const [mfa, setMfa] = useState('');
  const [userSaved, setUserSaved] = useState(null);
  const [loggedIn, setLoggedIn] = useState(false);

  const value = useMemo(() => ({
    cogid,
    setCogid,
    stagingFlag,
    setStagingFlag,
    email,
    setEmail,
    mfa,
    setMfa,
    userSaved,
    setUserSaved,
    loggedIn,
    setLoggedIn
  }), [cogid, stagingFlag, email, mfa, userSaved, loggedIn]);

  return (
    <AttributeContext.Provider value={value}>
      {props.children}
    </AttributeContext.Provider>
  );
};

export { Attribute, AttributeContext };