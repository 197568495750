import file from "../img/undraw_personal_file_re_5joy.svg";

function MDD() {
  // var i = 100;
  return (
    <>
      <h1 style={{ marginLeft: "10%", marginRight: "10%" }}>
        Reserved for IPAWS MDD content.
      </h1>
      <br />
      <h3 style={{ marginLeft: "10%", marginRight: "10%" }}>
        Please report this page if you are not seeing the MDD here.
      </h3>
      <img src={file} style={{ margin: "10%", maxWidth: "50%" }} alt="Page is Under Construction" fluid />
      {/* <ProgressBar animated now={i} style={{ margin: "10%" }} /> */}
    </>
  );
}
export default MDD;
