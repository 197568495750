import MessageViewerStg from '../components/appComponents/messageViewer/MessageViewerStg';
import {Container} from "react-bootstrap"
import UserDashboard from '../components/appComponents/userDashboard/UserDashboard';
import { useContext } from 'react';
import { AttributeContext } from '../components/universal/Attributes';

function MessageViewerStgPage() {
  const Attributes = useContext(AttributeContext);
  const { mfa } = Attributes;
  
  return (
    <>
    {mfa === "SOFTWARE_TOKEN_MFA" ? (
    <Container fluid>
      <MessageViewerStg />
    </Container>
    ) : (
      <UserDashboard />
    )}
  </>
  );
}

export default MessageViewerStgPage;
