import { Card } from "react-bootstrap";

const Contact = () => {
  return (
    <Card className="centeredCard mb-1">
      <Card.Body>
      <Card.Title as="h1">Contact</Card.Title>
      <br />
      Need help with something? Let us know.
      <br />
      
      {/* <Card.Link href="mailto:ipaws@fema.dhs.gov">ipaws@fema.dhs.gov</Card.Link> */}
      <br />
      <a href="mailto:fema-ipaws-lab@fema.dhs.gov" class="btn btn-primary">Contact By Email</a>
      
      <br />
      
      <hr />
      <Card.Title as="h1">IPAWS Link</Card.Title>
      <br />
      View the IPAWS page on FEMA's home website:
      <br />
      <br />
      
      <a href="https://www.fema.gov/ipaws" target="_blank" rel="noopener noreferrer" class="btn btn-primary">IPAWS Page on fema.gov</a>
      
      
      <br />
      {/* <Card.Link href="https://www.fema.gov/ipaws">www.fema.gov/ipaws</Card.Link> */}
      <br />
      
      
      </Card.Body>
    </Card>
  );
};

export default Contact;