/** @format */

import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  useCallback,
  Suspense,
} from "react";
import { debounce } from 'lodash';
// import AWS, { CognitoIdentityCredentials } from "aws-sdk";
import Pool from "../../UserPool";
import "cross-fetch/polyfill";
import {
  CognitoUser,
  AuthenticationDetails,
  CognitoAccessToken,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoUserSession
} from "amazon-cognito-identity-js";
import { useNavigate } from "react-router-dom";
import Layout from "../../pages/Layout";
import Env from "../../env.js";
import { AttributeContext } from "./Attributes";

const AccountContext = createContext();

const Account = (props) => {
  const [userSaved, setUserSaved] = useState(
    localStorage.getItem("userSavedLocal")
  );
  const [isMfaRequired, setIsMfaRequired] = useState(false);
  const [userData, setUserData] = useState({test: "data"});
  // let mfaUserData;

  //attribute context
  const Attributes = useContext(AttributeContext);
  const {
    setCogid,
    cogid,
    setStagingFlag,
    setEmail,
    setMfa,
    loggedIn,
    setLoggedIn,
  } = Attributes;

  const navigate = useNavigate();
  const apppage = Env.SLUG + "app";

  const pullTokens = useCallback(debounce((userData, dataData) => {
    const user = (userData || JSON.parse(sessionStorage.getItem("userSavedLocal"))?.user);
    // console.log("User from pullTokens: ", user);
    const data = (dataData || JSON.parse(sessionStorage.getItem("userSavedLocal"))?.data);
    // console.log("Data from pullTokens: ", data);

    if (user && data) {
    // console.log("From Account - pullTokens - running");
    // let user = JSON.parse(sessionStorage.getItem("userSavedLocal"));
    // console.log('USER FROM SESSIONSTORAGE IS: ', user);
    let attributes = user.signInUserSession.idToken.payload;
    // console.log("Is the attributes data here?", user.signInUserSession.idToken.payload);
    // if (data && user !== null) {
    new Promise((resolve, reject) => {
      const accessToken = new CognitoAccessToken({
        AccessToken: data.accessToken.jwtToken,
      });
      const idToken = new CognitoIdToken({
        IdToken: data.idToken.jwtToken,
      });
      const refreshToken = new CognitoRefreshToken({
        RefreshToken: data.refreshToken.token,
      });

      const sessionData = {
        IdToken: idToken,
        AccessToken: accessToken,
        RefreshToken: refreshToken,
      };
      const cachedSessionPull = new CognitoUserSession(sessionData);

      const validSession = async () => {
        if (cachedSessionPull === null) {
          // logout();
          return false;
        } else {
          try {
            if (await cachedSessionPull.isValid()) {
              setLoggedIn(true);
              getSession();
              pullUserAttributes(attributes);
              // console.log("Session data has been Validated");
              return true;
            } else {
              console.log(
                "From Account - pullTokens: cachedSession is not valid"
              );
              refreshExpiredSession(user, refreshToken);
              return false;
            }
          } catch (error) {
            console.error("Error validating cached session: ", error);
            refreshExpiredSession(
              user,
              refreshToken,
              cachedSessionPull
            );
            return false;
          }
        }
      };

      const refreshExpiredSession = async (user, refreshToken) => {
        user.refreshSession(refreshToken, (err, session) => {
          if (err) {
            console.error(
              "From Account - refreshExpiredSession - refreshSession: ",
              err
            );
            logout();
          } else {
            console.log(
              "From Account - refreshExpiredSession - refreshSession - success: ",
              session
            );
          }
        });
      };

      const pullUserAttributes = async (attributes) => {
        if (typeof attributes !== 'object' || attributes === null) {
          console.error("Attributes is not an object");
          return;
        }
      
        const results = {};
      
        for (let [Name, Value] of Object.entries(attributes)) {
          results[Name] = Value;
          if (Name === "custom:prefferedcogid") {
            setCogid(Value);
          }
          if (Name === "custom:staging") {
            setStagingFlag(Value);
          }
          if (Name === "email") {
            setEmail(Value);
          }
        }
        // console.log("User Attributes: ", results);
        return results;
      };

      validSession();

      resolve(cachedSessionPull);
    });
  }
}, 300), [setCogid, setStagingFlag, setEmail, setLoggedIn]);

  const getMFASetting = async (user) => {
    return new Promise((resolve, reject) => {
      user.getUserData((err, userData) => {
        if (err) {
          console.error(err);
          reject(err);
        } else {
          const mfaType = userData.PreferredMfaSetting;
          setMfa(mfaType);
          resolve(mfaType);
        }
      });
    });
  };

  const getSession = async () => {
    // console.log("From Account - getSession: ");
    return await new Promise((resolve, reject) => {
      const user = Pool.getCurrentUser();
      if (user) {
        user.getSession(async (err, session) => {
          if (err) {
            setLoggedIn(false);
            reject();
          } else {
            getMFASetting(user);

            resolve(user);
          }
        });
      } else {
        // console.log("No user is currently logged in.");
        setLoggedIn(false);
        //When I was troubleshooting the Userdashboard not refreshing the account email when I would change it, this was working. After adding the navigate back into this function it stropped working again.
        //Bug below was resolved when I came back for testing.
        //This line breaks the login page. Need to troubleshoot.
        // reject();
      }
    });
  };

  const pullAttributes = async () => {
    return await new Promise((resolve, reject) => {
      const user = Pool.getCurrentUser();
      if (user) {
        user.getSession(async (err, session) => {
          if (err) {
            reject();
          } else {
            const attributes = await new Promise((resolve, reject) => {
              user.getUserAttributes((err, attributes) => {
                if (err) {
                  reject(err);
                } else {
                  const results = {};

                  for (let attribute of attributes) {
                    const { Name, Value } = attribute;
                    results[Name] = Value;
                    if (Name === "custom:prefferedcogid") {
                      //console.log("prefferedcogid: ", Value);
                      setCogid(Value);
                    }
                    if (Name === "custom:staging") {
                      //console.log("prefferedcogid: ", Value);
                      setStagingFlag(Value);
                    }
                    if (Name === "email") {
                      //console.log("prefferedcogid: ", Value);
                      setEmail(Value);
                    }
                  }

                  user.getUserData((err, userData) => {
                    if (err) {
                      reject(err);
                    } else {
                      const mfaType = userData.PreferredMfaSetting;
                      setMfa(mfaType);
                      resolve(results);
                    }
                  });

                  resolve(results);
                }
              });
            });

            loadCOGintoHash();

            resolve({ user, ...session, ...attributes });
          }
        });
      } else {
        //When I was troubleshooting the Userdashboard not refreshing the account email when I would change it, this was working. After adding the navigate back into this function it stropped working again.
        //Bug below was resolved when I came back for testing.
        //This line breaks the login page. Need to troubleshoot.
        //reject();
      }
    });
  };

  //const authenticate function
  const authenticate = async (Username, Password) => {
    // console.log('INSIDE AUTHENTICATE');
    return await new Promise((resolve, reject) => {
      const user = new CognitoUser({ Username, Pool });
      // console.log('NEW USER CREATED: ', user);
      setUserData(user);

      const authDetails = new AuthenticationDetails({ Username, Password });

      user.authenticateUser(authDetails, {
        onSuccess: (data) => {
          // console.log("From Account - onSuccess: ", data);
          sessionStorage.setItem("username", Username);

          // Retrieve the access token
          // console.log("Setting the cached user and session");
          // console.log( "User: ", user);
          // console.log( "Data: ", data);
          setUserSaved({ user: user, data: data });
          sessionStorage.setItem(
            "userSavedLocal",
            JSON.stringify({ user: user, data: data })
          );

          // console.log("About to run pullTokens from authenticate");
          // getSession().then(() => {
            pullTokens();
            // });
          // console.log("pullTokens ran from authenticate");
          resolve(data);
          navigate(apppage);
        },

        onFailure: (err) => {
          // console.error("from Account - onFailure :", err);
          reject(err);
        },

        newPasswordRequired: (data) => {
          // console.log("newPasswordRequired: ", data);
          //new error(data)
          resolve(data);
        },

        mfaSetup: function (challengeName, challengeParameters) {
          user.associateSoftwareToken(this);
          setUserSaved({ user: user });
        },

        associateSecretCode: function (secretCode) {
          setUserSaved({ user: user });
          var challengeAnswer = prompt("Please input the TOTP code.", "");
          user.verifySoftwareToken(challengeAnswer, "My TOTP device", this);
        },

        selectMFAType: function (challengeName, challengeParameters) {
          setUserSaved({ user: user });
          var mfaType = prompt("Please select the MFA method.", ""); // valid values for mfaType is "SMS_MFA", "SOFTWARE_TOKEN_MFA"
          user.sendMFASelectionAnswer(mfaType, this);
        },

        totpRequired: function (secretCode) {
          setIsMfaRequired(true);
          setUserSaved({ user: user });
          // console.log("TOTP Required: ", secretCode);
          // user.sendMFACode(challengeAnswer, this, 'SOFTWARE_TOKEN_MFA');
        },

        mfaRequired: function (codeDeliveryDetails) {
          var verificationCode = prompt("Please input verification code", "");
          // console.log('ABOUT TO RUN user.sendMFAcode');
          user.sendMFACode(verificationCode, user, this);
        },
      });
    });
  };

  const sendMfaCode = (mfaCode, user) => {
    return new Promise((resolve, reject) => {
      // console.log('ATTEMPTING TO SEND MFACODE');
      // console.log('MFACODE IS IS', mfaCode);
      // console.log('USER IS', user);
      if (user) {
        user.sendMFACode(
          mfaCode,
          {
            onSuccess: function (result) {
              // console.log("From Account - sendMfaCode - result: ", result);

              sessionStorage.setItem(
                "userSavedLocal",
                JSON.stringify({
                  data: result,
                  user: userSaved.user,
                })
              );

              // setMfa("SOFTWARE_TOKEN_MFA");
              // getSession().then(() => {
              pullTokens();
              // });
              setUserSaved({ data: result, user: userSaved.user });

              setUserSaved({ data: result, user: userSaved.user });
              navigate(apppage);
              resolve(result);
            },
            onFailure: function (err) {
              console.error("From Account - sendMfaCode - onFailure: ", err);
              reject(err);
            },
          },
          "SOFTWARE_TOKEN_MFA"
        );
      } else {
        reject("No user is currently logged in.");
      }
    });
  };

  const logout = () => {
    const user = Pool.getCurrentUser();
    return new Promise((resolve, reject) => {
    if (user) {
      user.signOut();
      resolve();
    }
    }
  )
  };

  const resetPassword = async (Username) => {
    return await new Promise((resolve, reject) => {
      const user = new CognitoUser({ Username, Pool });

      // call forgotPassword on cognitoUser
      user.forgotPassword({
        onSuccess: function (result) {
          // console.log("user: ", user);
          // console.log("call result: " + result);
          resolve(result);
        },
        onFailure: function (err) {
          alert(err);
          reject(err);
        },
        inputVerificationCode() {
          // this is optional, and likely won't be implemented as in AWS's example (i.e, prompt to get info)
          var verificationCode = prompt(
            "Please input verification code that was sent to your email address. Please allow a few minutes for the code to be delivered.",
            ""
          );
          var newPassword = prompt("Enter your new replacement password", "");
          user.confirmPassword(verificationCode, newPassword, this);
        },
      });
    });
  };

  const loadCOGintoHash = () => {
    if (cogid === "" || cogid === null || cogid === undefined) {
      return;
    } else {
      window.location.hash = "cogid=" + cogid;
      //console.log("COG ID: ", cogid)
    }
  };

  useEffect(() => {
    // let storedPage = sessionStorage.getItem("currentPage");
    // let curentPageSlug = window.location.pathname;
    // console.log("Initial storedPage: " + storedPage);
    // console.log("Initial curentPageSlug: " + curentPageSlug);

    // if () {
    //   sessionStorage.setItem("currentPage", window.location.pathname);
    //   console.log("Stored Page: " + storedPage);
    //   console.log("Runnning pullTokens from useEffect");

    // console.log("From Account - useEffect - data: ", userSavedLocalData);
    pullTokens();

    // } 
    // else {
    //   console.log(
    //     "No stored page, setting value to: " + window.location.pathname
    //   );
    //   sessionStorage.setItem("currentPage", window.location.pathname);
    // }
  }, [pullTokens]);

  return (
    <AccountContext.Provider
      value={{
        authenticate,
        getSession,
        pullAttributes,
        logout,
        resetPassword,
        sendMfaCode,
        isMfaRequired,
        userData,
        // user,
      }}
    >
      {props.children}
      <Suspense fallback={<div>Loading...</div>}>
        <Layout loggedIn={loggedIn} />
      </Suspense>
    </AccountContext.Provider>
  );
};

export { Account, AccountContext };
