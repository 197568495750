import UserDashboard from '../components/appComponents/userDashboard/UserDashboard';
import {Container} from "react-bootstrap"

function UserDashboardPage() {
  return (
    <Container fluid>
      <UserDashboard />
    </Container>
  );
}

export default UserDashboardPage;
