import AppSelection from "../components/appComponents/appSelection/AppSelection";
import { Card, Container } from "react-bootstrap";
import { AttributeContext } from "../components/universal/Attributes";
import { useContext } from "react";
import UserDashboardPage from "./UserDashboardPage";

export default function AppPage() {
  const Attributes = useContext(AttributeContext);
  const { mfa } = Attributes;

  return (
    <>
      {mfa === "SOFTWARE_TOKEN_MFA" ? (
        <Container fluid>
          <Card>
            <AppSelection />
          </Card>
        </Container>
      ) : (
        <UserDashboardPage />
      )}
    </>
  );
}


