import React from 'react';
import { Card } from "react-bootstrap";

const LegalNotice = () => {
  return (
    <Card style={{marginLeft: '10%', marginRight: '10%', marginBottom: '5%'}}>
      <Card.Body style={{fontSize: 13}}>
        <h6>Legal Notice</h6>
        You are accessing a U.S. Government information system, which includes (1) this computer or device
    used to enable access to the government network, (2) this government network, (3) all computers or
    devices, virtual or otherwise, connected to this government network, and (4) all devices and storage
    media attached to this government network or to a computer or device on this government network.
    This information system is provided for U.S. Government-authorized use only.
        Unauthorized or improper use or access of this system may result in disciplinary action, as well as civil
    and criminal penalties.
    By using this information system, you understand and consent to the following:
        You have no reasonable expectation of privacy when you use this information system. This includes any communications or data transiting, stored on, originated from, or
    directed to this information system.
        At any time, and for any lawful government purpose, the government may monitor, intercept, search and seize any communication or data transiting, stored on, originated
    from, or directed to or from this information system.
        The government may disclose or use any communications or data transiting, stored on, originated from, or directed to or from this information system for any lawful
    government purpose.
        You are NOT authorized to process classified information on this information system.
      </Card.Body>
    </Card>
  );
}

export default LegalNotice;
