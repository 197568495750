/** @format */

import React, { useState, useContext, useCallback } from "react";
import { CognitoUserAttribute } from "amazon-cognito-identity-js";
import { AccountContext } from "../../universal/Account";
import { Button, Form, Stack, Alert } from "react-bootstrap";
import { AttributeContext } from "../../universal/Attributes.js";

// eslint-disable-next-line
const ChangeAdvancedOptions = () => {
  // Wrong Password Alerts
  const [PasswordAlert, setPasswordAlert] = useState(false);
  const [PasswordAlertMsg, setPasswordAlertMsg] = useState("");
  const [PasswordAlertMsgSubtitle, setPasswordAlertMsgSubtitle] = useState("");
  const [AlertType, setAlertType] = useState("danger");

  const { pullAttributes } = useContext(AccountContext);
  const { stagingFlag, setStagingFlag } = useContext(AttributeContext);
  const appVersion = process.env.REACT_APP_VER;

  const changeStagingAttribute = useCallback((value) => {
    pullAttributes()
      .then(({ user }) => {
        const attributes = [
          new CognitoUserAttribute({
            Name: "custom:staging",
            Value: value,
          }),
        ];

        user.updateAttributes(attributes, (err, results) => {
          if (err) {
            console.error(err);
            setPasswordAlert(true);
            setAlertType("danger");
            setPasswordAlertMsg("Error Changing Staging Access");
            setPasswordAlertMsgSubtitle(err.message);
          } else {
            setPasswordAlert(true);
            setAlertType("success");
            setPasswordAlertMsg(
              "Staging Access " +
                (value === "1" ? "Enabled" : "Disabled") +
                "!"
            );
            // setPasswordAlertMsgSubtitle(
            //   results.substring(0, 1) + results.substring(1).toLowerCase()
            // );
            setStagingFlag(value);
          }
        });
      })
      .catch((err) => {
        console.error("Catch: " + err);
        setPasswordAlert(true);
        setPasswordAlertMsg(err.message);
        setPasswordAlertMsgSubtitle("Please try again.");
      });
  }, [pullAttributes, setStagingFlag]);

  return (
    <div>
      <Stack gap={3}>
        <Stack gap={3}>
          <h5>IPAWS Alerting Authority ATP version: {appVersion}</h5>
          <hr />
        
          
          <Form.Group
            className="mb-3"
            controlId="formBasicflagChange"
          >
            <Form.Text
              id="flagHelpBlock"
              muted
            >
              This feature is intended for use by users who need access to the
              IPAWS TDL environment. If you are unsure if you need access to
              this environment, you most likely do not.
            </Form.Text>
            <br />
            <br />
            {stagingFlag === "0" ? (
              <Button
                variant="success"
                type="submit"
                onClick={() => changeStagingAttribute("1")}
              >
                Enable Staging Access
              </Button>
            ) : (
              <Button
                variant="warning"
                type="submit"
                onClick={() => changeStagingAttribute("0")}
              >
                Disable Staging Access
              </Button>
            )}
            
          </Form.Group>
        </Stack>

        {PasswordAlert && (
          <>
            <Alert
              className="PasswordAlert"
              show={PasswordAlert}
              onClose={() => setPasswordAlert(false)}
              variant={AlertType}
              dismissible
            >
              <Alert.Heading>{PasswordAlertMsg}</Alert.Heading>
              <p>{PasswordAlertMsgSubtitle}</p>
            </Alert>
          </>
        )}
      </Stack>
    </div>
  );
};

export default ChangeAdvancedOptions;
