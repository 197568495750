import { CognitoUserPool } from "amazon-cognito-identity-js";
import { cognitoConfig } from "./cognito/config";

const poolData = {
    UserPoolId: cognitoConfig.POOL_ID,
    ClientId: cognitoConfig.CLIENT_ID,
    // Storage: new CookieStorage({
    //     domain: ".ipawsatp.com",
    //     secure: true,
    //     expires: 365,
    //     path: "/",
    //     sameSite: "none"
    // })
}
const myCognitoUserPool = new CognitoUserPool(poolData);

export default myCognitoUserPool;