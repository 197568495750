import { useEffect } from "react";
import { useNavigate } from 'react-router'

const NoPage = () => {

  const navigate = useNavigate()

  useEffect(() => {
  navigate('/');
  })

  return (
    <>
      
    </>);
};

export default NoPage;