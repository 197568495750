import React, { useState, useContext, useCallback } from "react";
import { AccountContext } from "../../universal/Account";
import { Button, Form, FloatingLabel, Stack, Alert } from "react-bootstrap";

const ChangePassword = () => {
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [PasswordValidated, setPasswordValidated] = useState(false);
  const [passwordMatch, setPasswordMatch] = useState(false);
  const [PasswordAlert, setPasswordAlert] = useState(false);
  const [PasswordAlertMsg, setPasswordAlertMsg] = useState("");
  const [PasswordAlertMsgSubtitle, setPasswordAlertMsgSubtitle] = useState("");
  const [PasswordAlertType, setPasswordAlertType] = useState("danger");

  const { pullAttributes } = useContext(AccountContext);

  const onSubmit = useCallback((event) => {
    event.preventDefault(); // Prevents page from reloading on submit

    pullAttributes().then(({ user }) => {
      user.changePassword(password, newPassword, (err, result) => {
        if (err) {
          // console.error(err);
          setPasswordAlert(true);
          setPasswordAlertMsg(err.message);
          setPasswordAlertMsgSubtitle("Please try again.");
        } else {
          // console.log(result);
          setPasswordAlert(true);
          setPasswordAlertType("success");
          setPasswordAlertMsg("Password Changed Successfully!");
          setPasswordAlertMsgSubtitle("Success!");
        }
      });
    });
  }, [password, newPassword, pullAttributes]);

  // This uses RegEx to validate the password format. it then sets the state of the password and the password validation.
  const passwordValidation = (newPassword) => {
    setNewPassword(newPassword);
    var re = {
      lowercase: /(?=.*[a-z])/,
      capital: /(?=.*[A-Z])/,
      length: /(?=.{8,99}$)/,
      // eslint-disable-next-line
      specialChar: /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/,
      digit: /(?=.*[0-9])/,
    };
    setPasswordValidated(
      re.lowercase.test(newPassword) &&
      re.capital.test(newPassword) &&
      re.length.test(newPassword) &&
      re.specialChar.test(newPassword) &&
      re.digit.test(newPassword) &&
      password !== "" &&
      newPassword !== password
    );
    // console.log(
    //   "Password Validation: " +
    //     (
    //       re.lowercase.test(newPassword) &&
    //       re.capital.test(newPassword) &&
    //       re.length.test(newPassword) &&
    //       re.specialChar.test(newPassword) &&
    //       re.digit.test(newPassword) &&
    //       password !== "" &&
    //       newPassword !== password
    //     )
    // );
    setPasswordMatch(newPassword === password);
    // console.log("passwordMatch=" + passwordMatch);
    // console.log("!PasswordValidated= " + !PasswordValidated);
  };

  const oldPasswordValidation = (oldPassword) => {
    setPassword(oldPassword);
    var re = {
      lowercase: /(?=.*[a-z])/,
      capital: /(?=.*[A-Z])/,
      length: /(?=.{8,99}$)/,
      // eslint-disable-next-line
      specialChar: /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/,
      digit: /(?=.*[0-9])/,
    };
    setPasswordValidated(
      re.lowercase.test(newPassword) &&
        re.capital.test(newPassword) &&
        re.length.test(newPassword) &&
        re.specialChar.test(newPassword) &&
        re.digit.test(newPassword) &&
        oldPassword !== "" &&
        newPassword !== oldPassword
    );
    // console.log(
    //   "Password Validation: " +
    //     (re.lowercase.test(newPassword) &&
    //       re.capital.test(newPassword) &&
    //       re.length.test(newPassword) &&
    //       re.specialChar.test(newPassword) &&
    //       re.digit.test(newPassword)) &&
    //     oldPassword !== "" &&
    //     newPassword !== oldPassword
    // );
    setPasswordMatch(oldPassword === newPassword);
    // console.log("passwordMatch=" + passwordMatch);
    // console.log("!PasswordValidated= " + !PasswordValidated);
  };

  return (
    <div>
      <Form
        className="password-change-form"
        noValidate
        onSubmit={onSubmit}
        validated={passwordMatch}
      >
        {/* Input Field Stack */}
        <Stack gap={3}>
          <Form.Group className="mb-3" controlId="formCurrentPasswordChange">
            <FloatingLabel
              controlId="floatingInputCurrentPassword"
              label="Current Account Password"
              className="mb-2"
            >
              <Form.Control
                type="password"
                placeholder="Enter Current Password"
                value={password}
                onChange={(event) => oldPasswordValidation(event.target.value)}
                style={{ height: "50px" }}
              />
            </FloatingLabel>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formNewPasswordChange">
            <FloatingLabel
              controlId="floatingInputNewPassword"
              label="New Account Password"
              className="mb-2"
            >
              <Form.Control
                type="password"
                placeholder="Enter New Password"
                value={newPassword}
                onChange={(event) => passwordValidation(event.target.value)}
                style={{ height: "50px" }}
                aria-describedby="passwordReqHelpBlock"
              />
            </FloatingLabel>
            <Form.Text
              id="passwordReqHelpBlock"
              muted
              hidden={PasswordValidated}
            >
              Your password must meet these requirements: 8+ characters long, 1
              uppercase letter, 1 lowercase letter, 1 special character, and 1
              number.
            </Form.Text>
            <br />
            <Form.Text
              id="passwordButtonHelpBlock"
              muted
              hidden={PasswordValidated}
            >
              If the "Change Account Password" button is yellow, your password
              does not meet these requirements."
            </Form.Text>
          </Form.Group>
        </Stack>

        {/* Button Stack */}
        <Stack gap={3}>
          <Form.Group>
            <Button
              variant={PasswordValidated ? "success" : "warning"}
              type="submit"
              disabled={!PasswordValidated}
            >
              Change Account Password
            </Button>
          </Form.Group>
          <Alert
            className="PasswordAlert"
            show={PasswordAlert}
            onClose={() => setPasswordAlert(false)}
            variant={PasswordAlertType}
            dismissible
          >
            <Alert.Heading>{PasswordAlertMsg}</Alert.Heading>
            <p>{PasswordAlertMsgSubtitle}</p>
          </Alert>
        </Stack>
      </Form>
    </div>
  );
};

export default ChangePassword;
