import Test from '../components//testing/Test';

function TestPage() {
    return (
        <>
            <Test />
        </>
    );
}

export default TestPage;